import React,{Fragment, useState} from 'react'
import axios from "axios";
import {Link, navigate} from 'gatsby'
import Seo from "../../components/seo/seo";
import AOS from "aos";
import TextBox from "../../components/inputs/Textbox";
import PhoneNumber from "../../components/inputs/PhoneNumber";
import CheckBox from "../../components/inputs/CheckBox";
// import SelectBox from "../../components/inputs/SelectBox";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
// import countryList from '../../components/json/countryList'
// import industryList from '../../components/json/businessList'
import logo from '../../assets/img/virsat-logo-light.svg'
import { StaticImage } from "gatsby-plugin-image";
// import downloadPDF from "../../components/utils/downloadPDF"

function DownloadNewsletter({location}) {
  const [sending, setSending] = useState(false);
  const [errors, setErrors] = useState({});
//   const [success, setSuccess] = useState(false);

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [txtValues, setTxtValues] = useState({
    name: "",
    company: "",
    email: "",
    mobile: "",
    newsletter: 1,
    slug: location.state && location.state.slug ? location.state.slug : ''
  });

//   const [selectValues, setSelectValues] = useState({
//     country:"",
//     industry:"",
//   })

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    const checkbox_value = checked ? 1 : 0
    setTxtValues({ ...txtValues, [name]: type === "checkbox" ? checkbox_value : value });
  };

//   const handleSelectChange = e => {
//     setSelectValues({ ...selectValues, [e.name]: e.id })
//   }

  const sendData = async () => {
    setSending(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // const data = {...txtValues, ...selectValues}
    const data = {...txtValues}

    const body = JSON.stringify({ ...data });

    try {
      const res = await axios.post(
        `${process.env.GATSBY_API_URL}/download-article`,
        body,
        config
      );

      if (res.data.status) {
        resetValues()
        // setSuccess(true)
        // const link = `${process.env.GATSBY_HOMEPAGE}/${res.data.pdf_link}`
        // const filename = "VR Games_ A New Way to Make Work Significantly Safer"
        // downloadPDF(link,filename)

        // const details = `Thank you for downloading the article. If you have problem downloading, kinldy click the link below to download.<br/><br/> <a href=${link} download='${filename}' class='button-link'>Download PDF</a>`
        // navigate("/lp/thank-you/", {state: { details },})
        navigate("/lp/thank-you/")
      } else {
        setErrors(res.data.errors);
      }
      
      setSending(false);
    } catch (err) {
      setSending(false);
      setErrors({send_mail:1});
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendData();
  };

  const handleClose = () => {
    setErrors({});
  };

  const resetValues = ()=> {
    setErrors({});
    setTxtValues({
      name: "",
      company: "",
      email: "",
      newsletter:false
    });
    // setSelectValues({
    //   country:"",
    //   industry:"",
    // })
  }

  return (
    <Fragment>
      {sending && <Loading message="Please wait while we are processing your request." />}
      {errors.send_mail && <ErrorMessage handleClose={handleClose} />}
      <Seo
        title='Download Article | VIRSAT - Virtual Reality Safety Training'
        description='Download VIRSAT Article.'
      />
      
      <div className="main-container newsletter-lp">
        <div className="newsletterlp-bg">
          <StaticImage
            src='../../assets/img/pages/newsletter/virsat-newsletter-background.jpg'
            alt='VIRSAT Newsletter Background'
            placeholder='blurred'
            objectFit
            quality="100"
          />
        </div>
        <div className="row" data-aos='zoom-in' data-aos-duration='600'>
        <div className="column show-overflow">
                <div className="form-bg-container">
                <StaticImage
                    src='../../assets/img/pages/newsletter/virsat-form-newsletter-bg.jpg'
                    alt='VIRSAT Logo'
                    placeholder='blurred'
                    objectFit
                    quality="100"
                    style={{borderRadius:"15px"}}
                    imgStyle={{borderRadius:"15px"}}
                />
                </div>
                
                <div className="logo-container">
                <Link to="/">
                    <img src={logo} alt="VIRSAT Logo" title="VIRSAT Logo"/>
                </Link>
                </div>
                <div className="form-container">
                <h1 className="main-title small light">Get the Article</h1>
                <p className="light description justify">Get the article to know more information.</p>
                <form action='' method='post' onSubmit={handleSubmit}>
                    <div className='label-container'>
                    <TextBox
                        type='text'
                        id='name'
                        name='name'
                        placeholder='Your Name*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.name}
                        required
                        error={errors.name}
                    />
                    {errors.name && (
                        <div className='field-error secondary-color'>{errors.name[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                    <TextBox
                        type='text'
                        id='company'
                        name='company'
                        placeholder='Company Name*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.company}
                        required
                        error={errors.company}
                    />
                    {errors.company && (
                        <div className='field-error secondary-color'>{errors.company[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                    <TextBox
                        type='email'
                        id='email'
                        name='email'
                        placeholder='Company Email*'
                        txtBoxClass='regular-text'
                        onChange={handleInputChange}
                        value={txtValues.email}
                        required
                        error={errors.email}
                    />
                    {errors.email && (
                        <div className='field-error secondary-color'>{errors.email[0]}</div>
                    )}
                    </div>
                    <div className='label-container'>
                        <PhoneNumber
                        name='mobile'
                        id='mobile'
                        value={txtValues.mobile}
                        onChange={(mobile) =>
                            setTxtValues({ ...txtValues, mobile: mobile })
                        }
                        txtBoxClass='regular-text'
                        />
                        {errors.mobile && (
                        <div className='field-error'>{errors.mobile[0]}</div>
                        )}
                    </div>
                    <div className="checkbox-container">
                    <CheckBox
                        name="newsletter"
                        value={txtValues.newsletter}
                        onChange={handleInputChange}
                        checkboxBgClass="with-border"
                    />
                    <label htmlFor="newsletter" className="light small after-checkbox">I would like to receive more information about VIRSAT products, news and offers.</label>
                    </div>
                    <button type='submit' className="full-width" style={{marginTop:'10px'}}>Send Request</button>

                    <p className="light justify privacy-policy">We hate spam and promise to keep your email address safe. Check out our <Link to="/privacy-policy/" className="secondary"><strong>Privacy Policy</strong></Link>.</p>
                </form>
                </div>
            </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DownloadNewsletter
